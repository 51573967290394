import MetaTags from 'react-meta-tags';
import s from './LandingPageKoda.module.scss';
import { useMediaQuery } from 'react-responsive';
import React, { useEffect, useRef } from 'react';
import First from 'assets/img/landing-tips/1.png';
import Second from 'assets/img/landing-tips/2.png';
import Third from 'assets/img/landing-tips/3.png';
import Forth from 'assets/img/landing-tips/4.png';

import Translate from 'assets/img/koda-landing/translate.webp';
import Plane from 'assets/img/koda-landing/plane.webp';
import Opportunity from 'assets/img/koda-landing/opportunity.webp';
import Partners from 'assets/img/koda-landing/partners.webp';
import PartnersMobile from 'assets/img/koda-landing/partners-mobile.webp';

import Tip1 from 'assets/img/device/1.webp';
import Tip2 from 'assets/img/device/2.webp';
import Tip3 from 'assets/img/device/3.webp';
import Tip4 from 'assets/img/device/4.webp';
import TipPc1 from 'assets/img/device/pc-1.webp';
import TipPc2 from 'assets/img/device/pc-2.webp';
import TipPc3 from 'assets/img/device/pc-3.webp';
import TipPc4 from 'assets/img/device/pc-4.webp';

import Salary from 'assets/img/koda-landing/salary.webp';
import Skills from 'assets/img/koda-landing/skills.webp';
import Smartphone from 'assets/img/koda-landing/smartphone.webp';
import Businessman from 'assets/img/koda-landing/businessman.webp';
import Dollar from 'assets/img/koda-landing/dollar.webp';
import PC from 'assets/img/koda-landing/pc.webp';
import Duration from 'assets/img/koda-landing/duration.webp';
import Timeline from 'assets/img/koda-landing/timeline.webp';

import lazyRetry from 'utils/lazyRetry';

import mixpanel from 'mixpanel-browser';

const DemoFooter = React.lazy(() => lazyRetry(() => import('kandor-components/DemoFooter'), 'DemoFooter'));

const HomeSection = React.lazy(() => lazyRetry(() => import('./HomeSection/HomeSection'), 'HomeSection'));

const UniversitySection = React.lazy(() =>
  lazyRetry(() => import('pages/LandingPage/UniversitySection/UniversitySection.jsx'), 'UniversitySection')
);

const BookSection = React.lazy(() =>
  lazyRetry(() => import('pages/LandingPage/BookSection/BookSection.jsx'), 'BookSection')
);

const Navbar = React.lazy(() => lazyRetry(() => import('./Navbar/Navbar'), 'Navbar'));
const ColorNavbar = React.lazy(() => lazyRetry(() => import('kandor-components/ColorNavbar'), 'ColorNavbar'));

const Review = React.lazy(() => lazyRetry(() => import('../../landing-pages/Home/Review/Review'), 'Review'));

const NewListLanding = React.lazy(() =>
  lazyRetry(() => import('../LandingPageShortlister/NewListLanding/NewListLanding'), 'NewListLanding')
);

const TypeSection = React.lazy(() => lazyRetry(() => import('./TypeSection/TypeSection'), 'TypeSection'));

const LandingPageKoda = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const isMobileForHeader = useMediaQuery({ query: '(max-width: 1024px)' });
  const isFirstRender = useRef(true);

  const generateInsertId = () => {
    const timestamp = new Date().toISOString(); // Current timestamp
    const uniqueIdentifier = Math.random().toString(36).substr(2, 9); // Unique identifier

    const insertId = `${timestamp}-${uniqueIdentifier}`;

    return insertId;
  };

  useEffect(() => {
    const currentPath = window.location.pathname;
    localStorage.setItem('pathLanding', currentPath);
    if (isFirstRender.current) {
      isFirstRender.current = false;
      window.fbq('track', 'On Koda Landing Page');
      mixpanel.track('On Koda Landing Page', {
        insert_id: generateInsertId(),
      });
      return;
    }
  }, []);

  return (
    <>
      <MetaTags>
        <title>Kandor: IELTS Prep Platform</title>
        <meta
          id="meta-description"
          name="description"
          content="Navigate study abroad with our AI-powered products: Expert counseling, top college placements, and streamlined visa processes. Realize your global education dreams!"
        />
        <meta id="og-title" property="og:title" content="Kandor: IELTS Prep Platform" />
      </MetaTags>

      {isMobileForHeader ? <ColorNavbar /> : <Navbar />}
      <HomeSection />
      <div className={s.review}>
        <div className={s.reviewWrap}>
          <h2 className={s.reviewTitle}>Live Your dreams like 200k+ other students</h2>
        </div>
        <Review style={{ padding: 0 }} />
      </div>

      <div
        style={{
          background: isMobile ? 'white' : '#f9f7ff',
          paddingTop: '36px',
          paddingBottom: '36px',
        }}
        className={s.tipsWrap}>
        <div style={{ margin: '0 auto', maxWidth: '1220px' }}>
          <NewListLanding
            title="1. Career Finder"
            color="#47BA52"
            mainIcon={First}
            subTitle="Let AI match you with the perfect career path!"
            text={[
              'Get career insights based on global demand & salary trends.',
              'Find high-growth careers that align with your skills & goals.',
              'Unlock exclusive job opportunities for the future.',
            ]}
            mainImg={isMobile ? Tip1 : TipPc1}
            href="/signup"
            buttonText="Start your career journey today"
            tips={[
              {
                title: 'Average Salary',
                icon: (
                  <img src={Salary} loading="lazy" alt="Salary icon showing dollar sign" className={s.translateIcon} />
                ),
                value: <div className={s.priceValue}>$44,600/yr</div>,
              },
              {
                title: 'Skills Required',
                icon: (
                  <img
                    src={Skills}
                    alt="Skills icon showing gears and tools"
                    loading="lazy"
                    className={s.translateIcon}
                  />
                ),
                value: <div className={s.skillValue}>Artistic ability</div>,
              },
              {
                title: 'Job Demand',
                icon: (
                  <img
                    src={Smartphone}
                    loading="lazy"
                    alt="Job demand icon showing smartphone"
                    className={s.translateIcon}
                  />
                ),
                value: <div className={s.JobValue}>Stable</div>,
              },
            ]}
          />
        </div>
      </div>
      <div
        style={{ background: isMobile ? '#FBF6FF' : 'white', paddingTop: '36px', paddingBottom: '36px' }}
        className={s.tipsWrap}>
        <NewListLanding
          title="2. Country Guide"
          color="#E6C228"
          mainIcon={Second}
          subTitle="Explore top study destinations with visa secrets unlocked!"
          buttonText="Find your ideal study country"
          href="/signup"
          text={[
            'Compare countries based on job prospects, PR options & salaries.',
            'Get visa-friendly, high-return study destinations.',
            'Maximize your employability & future earnings.',
          ]}
          mainImg={isMobile ? Tip2 : TipPc2}
          reverse
          tips={[
            {
              title: 'Number of Jobs',
              icon: (
                <img
                  src={Businessman}
                  alt="Businessman icon representing job opportunities"
                  loading="lazy"
                  className={s.translateIcon}
                />
              ),
              value: <div className={s.JobNumValue}>200</div>,
            },
            {
              title: 'Language requirement',
              icon: (
                <img
                  src={Translate}
                  alt="Translation icon showing language options"
                  loading="lazy"
                  className={s.translateIcon}
                />
              ),
              value: (
                <div className={s.langValue}>
                  <p>
                    English (IELTS) - <span> 7+</span>
                  </p>
                  <p>
                    German - <span>B1</span>
                  </p>
                </div>
              ),
            },
            {
              title: 'Immigration Policy',
              icon: (
                <img
                  src={Plane}
                  loading="lazy"
                  alt="Airplane icon representing immigration and travel"
                  className={s.translateIcon}
                />
              ),
              value: (
                <div className={s.langValue}>
                  <p>
                    Post study work visa - <span>3 years</span>
                  </p>
                  <p>
                    Pathway to PR - <span>Easy</span>
                  </p>
                </div>
              ),
            },
          ]}
        />
      </div>
      <div
        style={{ background: isMobile ? 'white' : '#FBF6FF', paddingTop: '36px', paddingBottom: '36px' }}
        className={s.tipsWrap}>
        <NewListLanding
          title="3. Course Finder"
          color="#814BBD"
          mainIcon={Third}
          subTitle="Pick the perfect course—AI does the research for you!"
          text={[
            'Discover top courses that match your career goals.',
            'Compare tuition fees, scholarships & course durations.',
            'Find affordable, high-ROI study programs.',
          ]}
          href="/signup"
          mainImg={isMobile ? Tip3 : TipPc3}
          buttonText="Find your ideal course"
          tips={[
            {
              title: 'Average Tuition Fee',
              icon: (
                <img
                  src={Dollar}
                  loading="lazy"
                  alt="Dollar sign icon representing tuition fees"
                  className={s.translateIcon}
                />
              ),
              value: <div className={s.priceValue}>$44,600/yr</div>,
            },
            {
              title: 'Subjects',
              icon: (
                <img
                  src={PC}
                  alt="Computer icon representing course subjects"
                  loading="lazy"
                  className={s.translateIcon}
                />
              ),
              value: <div className={s.subjectValue}>Artistic ability</div>,
            },
            {
              title: 'Course Duration',
              icon: (
                <img
                  src={Duration}
                  alt="Clock icon showing course duration"
                  loading="lazy"
                  className={s.translateIcon}
                />
              ),
              value: <div className={s.courseValue}>48 Months</div>,
            },
          ]}
        />
      </div>
      <div
        style={{ background: isMobile ? '#FBF6FF' : 'white', paddingTop: '36px', paddingBottom: '36px' }}
        className={s.tipsWrap}>
        <NewListLanding
          title="4. College Shortlister"
          color="#F68D2B"
          mainIcon={Forth}
          subTitle="Secure your university spot—AI guarantees the best fit!"
          buttonText="Find your dream university"
          text={[
            'Get AI-backed shortlists based on admission chances, budget & career goals.',
            'Find universities with high acceptance rates & strong job placements.',
            'Save time & apply smarter.',
          ]}
          mainImg={isMobile ? Tip4 : TipPc4}
          reverse
          href="/signup"
          tips={[
            {
              title: 'Admit Chances',
              icon: (
                <img
                  loading="lazy"
                  src={Opportunity}
                  alt="Success rate icon showing admission chances"
                  className={s.translateIcon}
                />
              ),
              value: <div className={s.adminValue}>98%</div>,
            },
            {
              title: 'Deadline (Round one)',
              icon: (
                <img
                  src={Timeline}
                  loading="lazy"
                  alt="Calendar icon showing application deadline"
                  className={s.translateIcon}
                />
              ),
              value: <div className={s.deadLineValue}>24th July</div>,
            },
          ]}
        />
      </div>
      <TypeSection />
      <div className={s.partners}>
        <div className={s.partnersWrap}>
          <h3>Our Partners</h3>
          <p>
            200K+ students across 20+ countries got admitted to top universities worldwide with Kandor, trusted by
            leading education partners globally
          </p>
          <img
            loading="lazy"
            src={isMobileForHeader ? PartnersMobile : Partners}
            width={isMobileForHeader ? 376 : 779}
            height={isMobileForHeader ? 27 : 29}
            alt="Kandor's education partner logos and institutions"
            className={s.icon}
            sizes="(max-width: 1024px) 376px, 779px"
          />
        </div>
      </div>
      <UniversitySection />
      <BookSection type="shortlist" />
      <DemoFooter />
    </>
  );
};

export default LandingPageKoda;
