import React, { useEffect, useState } from 'react';
import './navbar.css';
import { Button } from 'reactstrap';
import { isApp, isIOS, isIOSApp, isMac } from 'actions/referrer';
import { CounselingModal } from 'kandor-components/CounselingButton';
import ModalWindow from 'kandor-components/Modal/ModalWindow';
import mixpanel from 'mixpanel-browser';
import { useHistory } from 'react-router-dom';
import { ReactComponent as AppleStoreIcon } from '../../assets/kandor-img/apple_logo.svg';
import { ReactComponent as GooglePlayIcon } from '../../assets/kandor-img/google-play.svg';

import { ReactComponent as Arrow } from '../../assets/img/landing-border/arr.svg';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

function NavBar({ withButton = true, withFooter = true, isShortlisterLogin = false }) {
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [fixed, setFixed] = useState(false);
  const [isOpenCounseling, setIsOpenCounseling] = useState(false);
  const history = useHistory();
  const location = useLocation();

  const clszFix = fixed ? 'fixed' : '';

  const newWidth = window.innerWidth > 0 ? window.innerWidth : window.screen.width;
  const [width, setWidth] = useState(newWidth);

  const isDesktop = width >= 991;

  useEffect(() => {
    const changeWidth = () => {
      window.innerWidth > 0 ? setWidth(window.innerWidth) : setWidth(window.screen.width);
    };
    window.addEventListener('resize', changeWidth);
    return () => window.removeEventListener('resize', changeWidth);
  }, []);

  useEffect(() => {
    const onScroll = e => {
      const documentHeight = document.documentElement.clientHeight;

      const top = window.pageYOffset;

      if (top >= documentHeight) {
        setFixed(true);
      } else {
        setFixed(false);
      }
    };
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  const toggleNavbar = e => {
    const width = window.innerWidth > 0 ? window.innerWidth : window.screen.width;
    if (open) {
      setOpen(false);
      if (window.location.pathname.includes('admin')) {
        document.querySelector('body').style.overflow = 'visible';
        document.querySelector('html').style.height = '';
        document.querySelector('body').style.height = '';
      } else {
        document.querySelector('body').style.overflow = '';
        document.querySelector('html').style.height = '100%';
        document.querySelector('body').style.height = '100%';
      }
      document.documentElement.classList.remove('nav-open');
    } else {
      setOpen(true);
      if (width < 991) {
        document.querySelector('body').style.overflow = 'hidden';
        document.documentElement.classList.add('nav-open');
      }
    }
  };

  const closeNavbar = () => {
    setOpen(false);
    if (window.location.pathname.includes('admin')) {
      document.querySelector('body').style.overflow = 'visible';
    } else {
      document.querySelector('body').style.overflow = '';
      document.querySelector('html').style.height = '100%';
      document.querySelector('body').style.height = '100%';
    }
    document.documentElement.classList.remove('nav-open');
  };

  const generateInsertId = () => {
    const timestamp = new Date().toISOString(); // Current timestamp
    const uniqueIdentifier = Math.random().toString(36).substr(2, 9); // Unique identifier

    // Combine timestamp and unique identifier to create the insert_id
    const insertId = `${timestamp}-${uniqueIdentifier}`;

    return insertId;
  };

  const activeRoute = routeName => {
    return history?.location.pathname.indexOf(routeName) > -1 ? true : false;
  };

  const navigate = () => {
    mixpanel.track('Clicked on CTA', {});
    //history.push('/shortlister-login');
    history.push('/signup');
  };

  const [showFooter, setShowFooter] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (location.pathname === '/koda') {
        const scrollThreshold = 300;
        if (window.scrollY > scrollThreshold) {
          setShowFooter(true);
        } else {
          setShowFooter(false);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  console.log('showFooter', showFooter);

  return (
    <>
      <ModalWindow show={show} setShow={setShow} />
      <CounselingModal isOpen={isOpenCounseling} onClose={() => setIsOpenCounseling(false)} />
      <div className={`navbar-wrap ${clszFix}`}>
        <div className={`nav-bar-fix ${isShortlisterLogin ? 'shadow' : ''}`}>
          <nav className="navbar-container">
            <div>
              <a
                onClick={e => {
                  mixpanel.track(`Go to Default Page`, {});

                  e.preventDefault();
                  closeNavbar();
                  history.push(isApp() || isIOSApp() ? '/register-app' : '/default');
                }}
                className="nav-logo"
                href="#">
                <img width="140" height="43" src={require('assets/kandor-img/kandor logo_black font.png')} alt="logo" />
              </a>
            </div>
            {!isApp() && !isIOSApp() && (
              <>
                <div className={`menu-item active`}>
                  <button
                    aria-label="burger-trigger"
                    className={`burger-trigger ${open ? 'active' : ''}`}
                    type="button"
                    onClick={toggleNavbar}>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                  </button>
                </div>

                <div className={`navbar-inner ${open ? 'active' : ''}`}>
                  <div className="navbar-center">
                    {!'/default'.includes(location.pathname) && (
                      <a
                        href="#"
                        className={`ielts-button title-gradient ${activeRoute('counseling') ? 'active' : ''}`}
                        onClick={e => {
                          e.preventDefault();
                          closeNavbar();
                          mixpanel.track(`Go to Counseling Page`, {});
                          history.push('/counseling');
                        }}>
                        Study Abroad
                      </a>
                    )}

                    {!(
                      ['/counseling', '/shortlister'].includes(location.pathname) ||
                      location.pathname.startsWith('/university-profile')
                    ) && (
                      <a
                        href="#"
                        className={`ielts-button title-gradient ${activeRoute('ielts') ? 'active' : ''}`}
                        onClick={e => {
                          e.preventDefault();
                          mixpanel.track(`Go to Ielts Page`, {});
                          closeNavbar();
                          history.push('/ielts');
                          document.getElementById('ai-speaking-tool').scrollIntoView({ behavior: 'smooth' });
                        }}>
                        IELTS
                      </a>
                    )}

                    <a
                      style={{ cursor: 'pointer' }}
                      className={`ielts-button title-gradient ${activeRoute('about-us') ? 'active' : ''}`}
                      onClick={e => {
                        e.preventDefault();
                        closeNavbar();
                        history.push('/about-us');
                        mixpanel.track(`Go to About Us`, {
                          insert_id: generateInsertId(),
                        });
                      }}>
                      About Us
                    </a>
                    <a
                      href="https://blog.trykandor.com"
                      target="_blank"
                      className="ielts-button title-gradient"
                      onClick={() => {
                        history.push('/ielts');
                        mixpanel.track(`Go to Blog`, {
                          insert_id: generateInsertId(),
                        });
                      }}>
                      Blogs
                    </a>
                  </div>
                  {withButton && (
                    <div className={`navbar-right-side`}>
                      {!isDesktop ? (
                        <></>
                      ) : !(
                          ['/counseling', '/shortlister'].includes(location.pathname) ||
                          location.pathname.startsWith('/university-profile')
                        ) ? (
                        !isMac() ? (
                          <Button
                            href="https://play.google.com/store/apps/details?id=com.trykandor.twa"
                            onClick={e => {
                              window.fbq('track', 'Download App');
                              mixpanel.track('Download app from button on desktop', { insert_id: generateInsertId() })
                            }}
                            className="p-2 navbar-btn navbar-btn--white"
                            target="_blank">
                            <span className="navbar-btn__icon">
                              <GooglePlayIcon />
                            </span>
                            Download App
                          </Button>
                        ) : (
                          <Button
                            href="https://apps.apple.com/in/app/kandor-ielts/id6446410798"
                            onClick={e => {
                              window.fbq('track', 'Download App');
                              mixpanel.track('Download ios app from button on desktop', {
                                insert_id: generateInsertId(),
                              })
                            }}
                            target="_blank"
                            className="p-2 navbar-btn navbar-btn--white">
                            <span className="navbar-btn__icon">
                              <AppleStoreIcon />
                            </span>
                            Download App
                          </Button>
                        )
                      ) : null}
                      {!(
                        ['/counseling', '/shortlister'].includes(location.pathname) ||
                        location.pathname.startsWith('/university-profile')
                      ) && (
                        <Button
                          className={`navbar-btn`}
                          onClick={e => {
                            e.preventDefault();
                            closeNavbar();
                            mixpanel.track(`Signup from navbar`, {});
                            history.push('/signup');
                          }}
                          color="info">
                          Sign Up for Free
                        </Button>
                      )}
                      {(['/counseling', '/shortlister'].includes(location.pathname) ||
                        location.pathname.startsWith('/university-profile')) && (
                        <>
                          <button onClick={navigate} className="navbar-btn new-button">
                            Global Career Ready in 3 Minutes <Arrow />
                          </button>
                        </>
                      )}
                    </div>
                  )}
                </div>
              </>
            )}
          </nav>
        </div>
      </div>
      {withFooter &&
        !isApp() &&
        !isIOSApp() &&
        (location.pathname === '/koda' ? (
          <div
            style={{
              opacity: showFooter ? '1' : '0',
              transform: showFooter ? 'translateY(0)' : 'translateY(100%)',
              transition: 'opacity 0.5s ease, transform 0.5s ease',
              pointerEvents: showFooter ? 'auto' : 'none',
            }}
            className={`mobile-footer-btn `}>
            {!(
              ['/counseling', '/shortlister'].includes(location.pathname) ||
              location.pathname.startsWith('/university-profile')
            ) && (
              <>
                {!isIOS() && !isMac() ? (
                  <Button
                    href="https://play.google.com/store/apps/details?id=com.trykandor.twa"
                    onClick={e => {
                      window.fbq('track', 'Download App');
                      mixpanel.track('Download app from button on Mobile footer', { insert_id: generateInsertId() })
                    }}
                    className="navbar-btn navbar-btn--white"
                    color="info"
                    style={{
                      background: 'white',
                      color: '#1f0644',
                      boxShadow: '0px 8.85363px 20.1219px rgba(32, 32, 32, 0.2)',
                    }}
                    target="_blank">
                    <span className="navbar-btn__icon">
                      <GooglePlayIcon />
                    </span>
                    Download App
                  </Button>
                ) : (
                  <Button
                    href="https://apps.apple.com/in/app/kandor-ielts/id6446410798"
                    onClick={e => {
                      window.fbq('track', 'Download App');
                      mixpanel.track('Download ios app from button on Mobile footer', { insert_id: generateInsertId() })
                    }}
                    target="_blank"
                    className="navbar-btn navbar-btn--white"
                    style={{
                      background: 'white',
                      color: '#1f0644',
                      boxShadow: '0px 8.85363px 20.1219px rgba(32, 32, 32, 0.2)',
                    }}
                    color="info">
                    <span className="navbar-btn__icon">
                      <AppleStoreIcon />
                    </span>
                    Download App
                  </Button>
                )}
              </>
            )}

            {!(
              ['/counseling', '/shortlister'].includes(location.pathname) ||
              location.pathname.startsWith('/university-profile')
            ) && (
              <Button
                onClick={e => {
                  e.preventDefault();
                  closeNavbar();
                  mixpanel.track(`Signup from mobile footer`, {});
                  history.push('/signup');
                }}
                className={`navbar-btn`}
                color="info">
                Sign up for Free
              </Button>
            )}
            {(['/counseling', '/shortlister'].includes(location.pathname) ||
              location.pathname.startsWith('/university-profile')) && (
              <>
                <button onClick={navigate} className="new-button">
                  Global Career Ready in 3 Minutes <Arrow />
                </button>
              </>
            )}
          </div>
        ) : (
          <div className={`mobile-footer-btn active `}>
            {!(
              ['/counseling', '/shortlister'].includes(location.pathname) ||
              location.pathname.startsWith('/university-profile')
            ) && (
              <>
                {!isIOS() && !isMac() ? (
                  <Button
                    href="https://play.google.com/store/apps/details?id=com.trykandor.twa"
                    onClick={e => {
                      window.fbq('track', 'Download App');
                      mixpanel.track('Download app from button on Mobile footer', { insert_id: generateInsertId() })
                    }}
                    className="navbar-btn navbar-btn--white"
                    color="info"
                    style={{
                      background: 'white',
                      color: '#1f0644',
                      boxShadow: '0px 8.85363px 20.1219px rgba(32, 32, 32, 0.2)',
                    }}
                    target="_blank">
                    <span className="navbar-btn__icon">
                      <GooglePlayIcon />
                    </span>
                    Download App
                  </Button>
                ) : (
                  <Button
                    href="https://apps.apple.com/in/app/kandor-ielts/id6446410798"
                    onClick={e => {
                      window.fbq('track', 'Download App');
                      mixpanel.track('Download ios app from button on Mobile footer', { insert_id: generateInsertId() })
                    }}
                    target="_blank"
                    className="navbar-btn navbar-btn--white"
                    style={{
                      background: 'white',
                      color: '#1f0644',
                      boxShadow: '0px 8.85363px 20.1219px rgba(32, 32, 32, 0.2)',
                    }}
                    color="info">
                    <span className="navbar-btn__icon">
                      <AppleStoreIcon />
                    </span>
                    Download App
                  </Button>
                )}
              </>
            )}

            {!(
              ['/counseling', '/shortlister'].includes(location.pathname) ||
              location.pathname.startsWith('/university-profile')
            ) && (
              <Button
                onClick={e => {
                  e.preventDefault();
                  closeNavbar();
                  mixpanel.track(`Signup from mobile footer`, {});
                  history.push('/signup');
                }}
                className={`navbar-btn`}
                color="info">
                Sign up for Free
              </Button>
            )}
            {(['/counseling', '/shortlister'].includes(location.pathname) ||
              location.pathname.startsWith('/university-profile')) && (
              <>
                <button onClick={navigate} className="new-button">
                  Global Career Ready in 3 Minutes <Arrow />
                </button>
              </>
            )}
          </div>
        ))}
    </>
  );
}

export default NavBar;
