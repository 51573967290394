import api from 'services/api';

export async function getAllShortlisters() {
  return await api.get('shortlister');
}

export async function getCities(country_obj) {
  return await api.post(`shortlister/cities`, country_obj);
}

export async function getShortlisterById(id, page = 1) {
  return await api.get(`shortlister/${id}`, {
    params: { page },
  });
}

export async function getSortShortlisterById(id, page = 1, sort_by = 'deadline', sort_order = 'desc') {
  return await api.get(`shortlister/${id}`, {
    params: { page, sort_by, sort_order },
  });
}

export async function searchShortlister(id, query) {
  return await api.get(`shortlister/search`, {
    params: { query },
  });
}

export async function getAllCoursesById(univ_id) {
  return await api.get(`shortlister/course/${univ_id}`);
}

export async function createShortlist(data) {
  return await api.post('shortlister', data);
}

export async function getUniversityById(university_id) {
  return await api.get(`shortlister/universities/${university_id}`);
}

export async function getAllCoursesFromShortlistUni(shortlist_id, university_id) {
  return await api.get(`shortlister/${shortlist_id}/university/${university_id}/courses`);
  // return await api.get(`shortlister/university/${university_id}/course`);
}

export async function wishlistCourse(
  course_id,
  type,
  title,
  country,
  rank_qs,
  location,
  logo_s3_link,
  courses,
  application_deadline
) {
  return await api.put(`shortlister/shortlist`, {
    course_id,
    type,
    title,
    country,
    rank_qs,
    location,
    logo_s3_link,
    courses,
    application_deadline,
  });
}

export async function removeCourseFromWishlist(course_id) {
  return await api.put(`shortlister/shortlist`, { course_id: course_id, type: 'remove' });
}

export async function getWishList() {
  return await api.get(`shortlister/shortlist`);
}

export async function getCoursesById(course_id) {
  return await api.get(`/shortlister/course/${course_id}`);
}

export async function getCourseGroups(degree) {
  return await api.post(`shortlister/courses/group`, {
    degree: degree,
  });
}

export async function getSubCourses(selected, degree) {
  return await api.post(`shortlister/courses/group/subgroup`, {
    selected: selected,
    degree: degree,
  });
}

export async function getProfessionsCourse(selected, degree) {
  return await api.post(`shortlister/courses/group/subgroup/professions`, {
    selected: selected,
    degree: degree,
  });
}

export async function getProfessionsCountry(selected) {
  return await api.post(`shortlister/professions/countries`, {
    selected: selected,
  });
}

export async function getSpecialization(category, subCategory, country, degree) {
  return await api.post(`shortlister/courses/group/subgroup/specialization`, {
    category: category,
    subCategory: subCategory,
    country: country,
    degree: degree,
  });
}
