import React, { useState, useEffect } from "react";
import s from './DownloadAppBanner.module.scss';
import LogoIcon from '../../assets/icons/marketplace/modal/logo.png';
import { isIOS, isMobileBrowser, isApp, isIOSApp, isPwa } from "../../actions/referrer";
import { useMediaQuery } from "react-responsive";

const BANNER_HEIGHT = 70;

const DownloadAppBanner = () => {
  const isSmallScreen = useMediaQuery({ maxWidth: 768 }); 
  const [appURL, setAppURL] = useState("");
  const [isClosed, setIsClosed] = useState(false);
  const [shouldShowBanner, setShouldShowBanner] = useState(false);

  const checkShouldShowBanner = () => {
    const isAppEnvironment = isApp() || isIOSApp() || isPwa();
    const closedBanner = sessionStorage.getItem("downloadAppBannerClosed") === "true";
    const isMobile = isMobileBrowser() && window.innerWidth <= 768;
    
    return isMobile && !isAppEnvironment && !closedBanner;
  };

  const setBannerHeight = (show) => {
    const height = show ? BANNER_HEIGHT : 0;
    document.documentElement.style.setProperty("--banner-height", `${height}px`);
    sessionStorage.setItem("isBannerVisible", show.toString());
  };

  useEffect(() => {
    setAppURL(isIOS() 
      ? "https://apps.apple.com/in/app/kandor-ielts/id6446410798"
      : "https://play.google.com/store/apps/details?id=com.trykandor.twa"
    );

    const should = checkShouldShowBanner();
    setShouldShowBanner(should);
    setBannerHeight(should);

    if (!should && !isClosed) {
      setIsClosed(true);
      sessionStorage.setItem("downloadAppBannerClosed", "true");
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const should = checkShouldShowBanner();
      setShouldShowBanner(should);
      setBannerHeight(should);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [isClosed]);

  useEffect(() => {
    const isAppEnvironment = isApp() || isIOSApp() || isPwa();
    if (isAppEnvironment) {
      setIsClosed(true);
      setShouldShowBanner(false);
      setBannerHeight(false);
      sessionStorage.setItem("downloadAppBannerClosed", "true");
      sessionStorage.setItem("isBannerVisible", "false");
    }
  }, []);

  const closeBanner = () => {
    setIsClosed(true);
    setShouldShowBanner(false);
    setBannerHeight(false);
    sessionStorage.setItem("downloadAppBannerClosed", "true");
    sessionStorage.setItem("isBannerVisible", "false");
  };

  if (!shouldShowBanner || isClosed) {
    setBannerHeight(false)
    return null;
  }

  return (
    <div className={s.banner}>
      <div className={s.banner__wrap}>
        <button className={s.banner__close} onClick={closeBanner}></button>
        <div className={s.banner__icon}>
          <img src={LogoIcon} alt="logo" />
        </div>
        <p className={s.banner__text}>
          Study Abroad is a long journey.  
          Download App for regular updates!
        </p>
        <a href={appURL} className={s.banner__btn}>
          Download Now
        </a>
      </div>
    </div>
  );
};

export default DownloadAppBanner;