import ColorNavbar from 'kandor-components/ColorNavbar.js';
import Home from 'landing-pages/Home/index.js';
import React, { Suspense, useEffect, useRef, useState } from 'react';
import MetaTags from 'react-meta-tags';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import lazyRetry from './../utils/lazyRetry';
import mixpanel from 'mixpanel-browser';
import BannersForFestivalMobile from 'kandor-components/BannersForFestival/MobileBaner/BannersForFestivalMobile';
import { monthlyData } from './LandingPageShortlister/monthlyData';
import { useMediaQuery } from 'react-responsive';
import BannersForFestival from 'kandor-components/BannersForFestival/BannersForFestival';
import styles from './LandingPageIelts.module.scss';

const DemoFooter = React.lazy(() => lazyRetry(() => import('kandor-components/DemoFooter'), 'DemoFooter'));

const LandingPageIelts = () => {
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const isMobileForBanner = useMediaQuery({ query: '(max-width: 991px)' });
  const [monthContent, setMonthContent] = useState(null);
  const wrapRef = useRef('');
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    document.body.classList.toggle('white-content', true);
    wrapRef.current = 'wrapper';
    document.querySelector('body').style.overflow = '';
    // Clean up class when component unmounts
    return () => {
      document.body.classList.toggle('white-content', false);
    };
  }, []);

  const isFirstRender = useRef(true);

  const generateInsertId = () => {
    const timestamp = new Date().toISOString(); // Current timestamp
    const uniqueIdentifier = Math.random().toString(36).substr(2, 9); // Unique identifier

    // Combine timestamp and unique identifier to create the insert_id
    const insertId = `${timestamp}-${uniqueIdentifier}`;

    return insertId;
  };

  useEffect(() => {
    const currentPath = window.location.pathname;
    localStorage.setItem('pathLanding', currentPath);
    if (isFirstRender.current) {
      isFirstRender.current = false;
      window.fbq('track', 'On IELTS Landing Page');
      mixpanel.track('On IELTS Landing Page', { insert_id: generateInsertId() });
      return;
    }
  }, []);
  // useEffect(() => {
  //   const currentMonth = new Date().getMonth();
  //   setMonthContent(monthlyData[currentMonth]);
  //   const popupTimer = setTimeout(() => {
  //     setShowPopup(true);
  //   }, 15000);

  //   return () => clearTimeout(popupTimer);
  // }, []);
  const handleClosePopup = () => setShowPopup(false);
  const handleOpenPopup = () => setShowPopup(true);
  if (isAuthenticated) {
    return <Redirect to={'/admin/dashboard'} />;
  }

  return (
    <>
      <ColorNavbar />
      <Home />
      <MetaTags>
        <title>Kandor: IELTS Prep Platform</title>
        <meta
          id="meta-description"
          name="description"
          content="Boost your IELTS prep with AI-powered mock tests and practice sessions. Get instant feedback and accurate band scores in just 15 seconds. Ace your exam easily!"
        />
        <meta id="og-title" property="og:title" content="Kandor: IELTS Prep Platform" />
      </MetaTags>

      {showPopup && (
        <div className={styles.modalOverlay}>
          <div className={styles.modalContent}>
            <button className={styles.closeButton} onClick={handleClosePopup}>
              ×
            </button>
            {monthContent && isMobileForBanner ? (
              <BannersForFestivalMobile {...monthContent} />
            ) : (
              <BannersForFestival {...monthContent} />
            )}
          </div>
        </div>
      )}
      <Suspense fallback={<div></div>}>
        <DemoFooter />
      </Suspense>
    </>
  );
};

export default LandingPageIelts;

//<script
//type="text/javascript"
//src="https://app.termly.io/embed.min.js"
//data-auto-block="on"
//data-website-uuid="e1c34009-4364-499a-b4d6-b6b56efcae96"
//></script>
